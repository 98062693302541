<template>
  <v-card>
    <v-card-title class="align-start">
      <span>BRANCH MANAGER INCENTIVES</span>

    </v-card-title>

    <v-card-text>

      <v-data-table
        class="pt-4"
        :headers="headers"
        :items="listOfSales"
        :loading="data_table_loading"
        loading-text="Loading... Please wait"
        hide-default-footer
        disable-pagination
      >
        <template v-slot:top>
          <v-toolbar flat>
            <v-select
              v-model="month_of"
              class="mx-3 pt-5"
              :items="month_of_items"
              item-value="id"
              item-text="month_of"
              label="Month Of"
              @change="selected_month"
              dense
            ></v-select>
            <v-flex xs12 md4>
                        <span class="headline" v-if="listOfSales.length>0">
                                                Print
                                                <v-icon
                                                  class="mr-2"
                                                  color="warning"
                                                  @click="print_data()"
                                                >
                                                {{icons.mdiPrinter}}
                                                </v-icon>
                                            </span>
            </v-flex>
            <v-spacer></v-spacer>
          </v-toolbar>
        </template>
      </v-data-table>
    </v-card-text>
  </v-card>
</template>

<script>
  import {mdiDotsVertical, mdiChevronUp, mdiPrinter} from '@mdi/js'
  import {mapActions, mapGetters} from "vuex";
  import moment from "moment";

  export default {
    setup() {
      return {
        icons: {
          mdiDotsVertical,
          mdiChevronUp,
          mdiPrinter,
        },
      }
    },
    data() {
      return {
        listOfSales: [],
        month_of: '',
        month_of_items: [],
        data_table_loading: false,
        headers: [
          {text: 'Category', value: 'category', sortable: false},
          {text: 'Status', value: 'status', sortable: false},
          {text: 'Incentives', value: 'incentives', sortable: false},
        ],
      }
    },
    mounted() {
      if (this.branch_id > 1) {
        this.retrieve_transaction_month({
          branch_id: this.branch_id,
        })
          .then(response => {
            this.month_of_items = response.data
          })
          .catch(error => {
            console.log(error)
          })
      }
    },
    computed: {
      ...mapGetters('system_data', ['company_logo']),
      ...mapGetters('authentication', ['branch_id', 'position', 'user_id', 'branch', 'branch_contact_no', 'branch_address']),
    },
    methods: {
      ...mapActions('transaction_months', ['retrieve_transaction_month']),
      ...mapActions('summary_report', ['get_bmi_report']),
      selected_month() {
        if (this.month_of_items.length > 0) {
          var index = this.month_of_items.map(function (x) {
            return x.id
          }).indexOf(this.month_of)
          if (index != -1) {
            const data = new FormData()
            data.append('branch_id', this.branch_id);
            data.append('month_of', this.month_of_items[index].month_of);
            data.append('month_end', this.month_of_items[index].month_end);
            data.append('is_new_month', 0);
            data.append('is_prev', index > 0 ? 1 : 0);
            this.get_bmi_report(data)
              .then((response) => {
                this.listOfSales = response.data
              })
              .catch(error => {
                console.log(error)
              })
          }
        }
      },
      print_data() {
        var imgData = this.company_logo
        var array = []
        var indexMonth = this.month_of_items.map(function (x) {
          return x.id
        }).indexOf(this.month_of)

        var month = this.month_of_items[indexMonth].month_of
        array.push(
          [
            {text: 'Category', alignment: 'center', style: 'subheader'},
            {text: 'Status', alignment: 'center', style: 'subheader'},
            {text: 'Incentives', alignment: 'center', style: 'subheader'},
          ]
        )
        if (this.listOfSales.length > 0) {
          this.listOfSales.forEach(function (item) {
            array.push(
              [
                {text: item.category, alignment: 'left', style: 'main_info'},
                {text: item.status, alignment: 'center', style: 'main_info'},
                {text: item.incentives, alignment: 'center', style: 'main_info'},
              ]
            )
          })
        } else {
          array.push(
            [
              '----',
              '----',
              '----',
            ]
          )
        }
        //call pdfmake
        var pdfMake = require('pdfmake/build/pdfmake.js')
        if (pdfMake.vfs == undefined) {
          var pdfFonts = require('pdfmake/build/vfs_fonts.js')
          pdfMake.vfs = pdfFonts.pdfMake.vfs;
        }
        var docDefinition = {
          pageSize: 'LETTER',
          pageOrientation: 'portrait',
          content: [
            {
              columns: [
                {image: imgData, width: 54, height: 54, style: 'logo'},
                {
                  stack: [
                    'GOODLIFE SAVINGS & CREDIT COOPERATIVE',
                    {text: this.branch_address, style: 'subheader'},
                    {
                      text: 'C.D.A. REG. No. 9520-1011000000048747 CONTACT NO.: ' + this.branch_contact_no,
                      style: 'subheader'
                    },
                  ],
                  style: 'header'
                },
              ]
            },
            {text: 'BRANCH MANAGER INCENTIVES', style: 'title'},
            '================================================================================',
            {text: 'Printed as of: ' + moment().format('MMMM D, YYYY'), style: 'printed_label'},
            {text: 'Branch: ' + this.branch, style: 'main_info'},
            {text: 'Month of: ' + month, style: 'main_info'},
            ' ',
            {
              style: 'tableExample',
              color: '#444',
              table: {
                widths: [200, 150, 100],
                body: array,
              },
              layout: {
                fillColor: function (rowIndex) {
                  return (rowIndex === 0) ? '#CCCCCC' : null;
                },
              },
            },
            {
              stack: [
                {
                  columns: [
                    {text: 'Prepared By: ', style: 'aa_top_margin'},
                    {text: 'Received By:', style: 'cashier_top_margin'},
                    {text: ''},
                  ]
                },
                {
                  columns: [
                    {text: '', style: 'aa_for'},
                    {text: '', style: 'cashier_for'},
                    {text: ''},
                  ]
                },
                {
                  columns: [
                    {text: '_________________________________', style: 'aa_line'},
                    {text: '_________________________________', style: 'cashier_line'},
                    {text: ''},
                  ]
                },
              ]
            },
            {
              stack: [
                {
                  columns: [
                    {text: 'Verified By: ', style: 'bm_top_margin'},
                    {text: 'Audited By: ', style: 'au_top_margin'},
                  ]
                },
                {
                  columns: [
                    {text: '', style: 'bm_for'},
                    {text: '', style: 'au_for'},
                  ]
                },
                {
                  columns: [
                    {text: '_________________________________', style: 'bm_line'},
                    {text: '_________________________________', style: 'au_line'},
                  ]
                },
                {
                  columns: [
                    {text: 'Branch Manager', style: 'bm_placeholder'},
                    {text: 'Asst. Auditor', style: 'au_placeholder'},
                  ]
                },
              ]
            },
          ],
          footer: {
            columns: [
              {
                text: 'Generated with Goodlife Savings & Credit Cooperative System',
                alignment: 'center',
                style: 'tableExample'
              }
            ]
          },
          styles: {
            header: {
              fontSize: 21,
              bold: true,
              alignment: 'left',
              margin: [6, 6, 0, 20]//[left, top, right, bottom]
            },
            subheader: {
              fontSize: 12
            },
            title: {
              fontSize: 15,
              alignment: 'center',
            },
            logo: {
              alignment: 'center',
              margin: [0, 0, 0, 0]//[left, top, right, bottom]
            },
            printed_label: {
              alignment: 'right',
              fontSize: 9,
              margin: [0, 4, 0, 0]//[left, top, right, bottom]
            },
            main_info: {
              margin: [0, 2, 0, 2],
              fontSize: 10,
            },
            sub_info: {
              margin: [0, 2, 0, 2],
              fontSize: 8,
            },
            tableExample: {
              fontSize: 7,
            },

            aa_top_margin: {
              margin: [0, 10, 0, 0],
              alignment: 'left',
              fontSize: 7,
            },
            cashier_top_margin: {
              margin: [0, 10, 0, 0],
              alignment: 'left',
              fontSize: 7,
            },
            bm_top_margin: {
              margin: [0, 10, 0, 0],
              alignment: 'left',
              fontSize: 7,
            },
            cs_top_margin: {
              margin: [0, 10, 0, 0],
              alignment: 'left',
              fontSize: 7,
            },
            aa_line: {
              margin: [0, 0, 0, 2],
              alignment: 'left',
              fontSize: 7,
            },
            cashier_line: {
              margin: [0, 0, 0, 2],
              alignment: 'left',
              fontSize: 7,
            },
            cs_line: {
              margin: [0, 0, 0, 2],
              alignment: 'left',
              fontSize: 7,
            },
            bm_line: {
              margin: [0, 0, 0, 2],
              alignment: 'left',
              fontSize: 7,
            },
            aa_placeholder: {
              margin: [15, 2, 0, 2],
              alignment: 'left',
              fontSize: 7,
            },
            aa_for: {
              margin: [0, 15, 0, 2],
              alignment: 'left',
              fontSize: 7,
            },
            cs_for: {
              margin: [2, 15, 0, 2],
              alignment: 'left',
              fontSize: 7,
            },
            cashier_for: {
              margin: [3, 15, 0, 2],
              alignment: 'left',
              fontSize: 7,
            },
            bm_for: {
              margin: [3, 15, 0, 2],
              alignment: 'left',
              fontSize: 7,
            },
            cashier_placeholder: {
              margin: [26, 2, 0, 2],
              alignment: 'left',
              fontSize: 7,
            },
            cs_placeholder: {
              margin: [2, 2, 0, 2],
              alignment: 'left',
              fontSize: 7,
            },
            bm_placeholder: {
              margin: [5, 2, 0, 2],
              alignment: 'left',
              fontSize: 7,
            },
            au_top_margin: {
              margin: [0, 10, 0, 0],
              alignment: 'left',
              fontSize: 7,
            },
            au_placeholder: {
              margin: [2, 2, 0, 2],
              alignment: 'left',
              fontSize: 7,
            },
            au_for: {
              margin: [2, 15, 0, 2],
              alignment: 'left',
              fontSize: 7,
            },
            au_line: {
              margin: [0, 0, 0, 2],
              alignment: 'left',
              fontSize: 7,
            },
          },
        }
        pdfMake.createPdf(docDefinition).open();
      },
    },
  }
</script>
